import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function Videopopup({videourl,triggerr}) {


const contentStyle = { background: '#fff', width:'80%' };
const overlayStyle = { background: 'rgba(0,0,0,0.5)' };
const arrowStyle = { color: '#000' }; // style for an svg element
const [windowsize, setWindowSize] = React.useState(window.innerWidth);

const handleWindowResize = React.useCallback(event => {

  setWindowSize(window.innerWidth);

}, []); 

React.useEffect(() => {
  window.addEventListener('resize', handleWindowResize);

  return () => {
    window.removeEventListener('resize', handleWindowResize);
  };
}, [handleWindowResize]);


    return (

    <>
    <Popup    
    trigger={triggerr}
    modal {...{ contentStyle, windowsize , overlayStyle , arrowStyle }}
  >
    {close => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <div class="container01">
  <iframe allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen frameBorder="0" class="responsive-iframe01" src={videourl}></iframe>
</div>
        </div>
    
    )}
  </Popup>
    </>
    
    )
}

export default Videopopup