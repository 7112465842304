import './style.css';
import './font-awesome.min.css';
import './bootstrap.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes, Route, Link, NavLink } from "react-router-dom";
import axios from 'axios';
import logo from './graphics/logo.png' 
import { useEffect, useState, Suspense, lazy } from 'react';
import { useAtom } from 'jotai';
import { status } from './Jotai';
import {Coinloader} from './Loader'
import { useNavigate } from 'react-router-dom';
import Articlewriter from './toolpages/Articlewriter';
import Bgimg from './graphics/writing.png'



const Home = lazy(() => import('./Toollist'));
    const Login = lazy(() => import('./Login'));
    const Createaccount = lazy(() => import('./Createaccount'));
    const Recover = lazy(() => import('./Recover'));
    const Pricing = lazy(() => import('./Pricing'));
    const Faqs = lazy(() => import('./Faqs'));
    const Contactus = lazy(() => import('./Contactus'));
    const Accountsettings = lazy(() => import('./Accountsettings'));
    const Billing = lazy(() => import('./Billing'));
    const Success = lazy(() => import('./Success'));






function App() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [login, setLogin] = useAtom(status);

    function logout(e) {
        localStorage.removeItem('token')
        setLogin(false)
        navigate('login')
        toast.success('You are logged out!')
    }

    const openModal = (e) => {
        document.body.classList.add('offcanvas-open');
        setOpen(true);
      }
      const hideModal = (e) => {
        document.body.classList.remove('offcanvas-open');
        setOpen(false)
      }
      
    const [submenu, setSubmenu] = useState({
        help: false,
        account: false
    });

    useEffect(()=>{
        if(localStorage.getItem('token')) {
            axios.get(`https://api.aiwritergo.com/checklogin`,{
            headers: {
                Authorization: localStorage.getItem('token')
            }
        })
        .then(res => {
          setLogin(res.data.loginstatus)
          console.log(res.data)
        }).catch(error => {
            setLogin(false)
        })
        } else {
            setLogin(false)
        }
        
    },[])



  return (
    <>
    <div style={{paddingLeft: 10, paddingRight: 10}}>

    <header style={{zIndex: 222}} class="header-section d-lg-block d-none">
        
        <div class="header-bottom sticky-header"> 
            <div class="container piara">
                <div class="row">
                    <div class="col-12">
                        
                        <div class="main-menu">
                            <nav>
                                <ul>
                                    <li>
                                    <div class="mobile-header--left" style={{ marginRight: 100 }}>
                            <a href="" class="mobile-logo-link">
                      
                            <img src={logo} width={220} height={60} />
                            </a>
                        </div>
                                    </li>
                                    <li class="has-dropdown">
                                    <a href="https://aiwritergo.com">Home</a>
                                    </li>
                                    <li class="has-dropdown">
                                    <NavLink to="/">AI Writing Tool</NavLink>
                                    </li>
                                    <li style={{zIndex: 222}} class="has-dropdown">
                                    {/* <a href="#">Help Center <i class="fa fa-angle-down"></i></a> */}
                                    <Link class='' to='#'>Help Center <i class="fa fa-angle-down"></i></Link>
                                        <ul class="sub-menu">
                                        <li><NavLink style={{fontSize:'15px',fontWeight:'bold'}} to="faqs">FAQs</NavLink></li>
                                        <li><NavLink style={{fontSize:'15px',fontWeight:'bold'}} to="contactus">Contact Support</NavLink></li>
                                        </ul>
                                    </li>
                                    <li class="has-dropdown">
                                    <NavLink to="pricing">Pricing</NavLink>
                                    </li>
                                    

                                    {login ? 
                                    <>
                                <li class="has-dropdown">
                                <a href="#">My Account <i class="fa fa-angle-down"></i></a>
                                    <ul class="sub-menu">
                                        <li><NavLink style={{fontSize:'15px',fontWeight:'bold'}} to="account-settings">Account Settings</NavLink></li>
                                        <li><NavLink style={{fontSize:'15px',fontWeight:'bold'}} to="billing">Billing</NavLink></li>
                                    </ul>
                                </li>
                                <li class="has-dropdown">
                                <a onClick={(e) => logout(e)} href="#">Log Out</a>
                                </li>
                                </>
                                :
                                <>
                                <li>
                                        <NavLink to="login">Sign In</NavLink>
                                    </li>
                                    <li>
                                    <NavLink to="signup">Create Account</NavLink>
                                    </li>
                                    </>
                                }
                                    
                                </ul>
                            </nav>
                        </div> 
                        
                    </div>
                </div>
            </div>
        </div> 
    </header> 
    <div class="mobile-header-section d-block d-lg-none">
        
        <div class="mobile-header-wrapper">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex justify-content-between align-items-center">
                        <div class="mobile-header--left">
                            <a href="" class="mobile-logo-link">
                  
                            <img style={{marginTop: '7px'}} src={logo} width={190} height={50} />
                            </a>
                        </div>
                        <div onClick={e => openModal(e)} class="mobile-header--right">
                            <a href="javascript:(0)" class="mobile-menu offcanvas-toggle">
                                <span class="mobile-menu-dash"></span>
                                <span class="mobile-menu-dash"></span>
                                <span class="mobile-menu-dash"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div> 
    <div id="mobile-menu-offcanvas" class={open ? "offcanvas offcanvas-leftside offcanvas-mobile-menu-section offcanvas-open" : "offcanvas offcanvas-leftside offcanvas-mobile-menu-section"}>
      
        <div class="offcanvas-header text-right">
            <button onClick={e => hideModal(e)} class="offcanvas-close"><i class="fa fa-times"></i></button>
        </div> 
        <div class="offcanvas-mobile-menu-wrapper">
            <div class="mobile-menu-top">
                <span>Welcome to <b>AI Writer Go!</b></span>
            </div> 
            <div class="mobile-menu-center">
                <div class="offcanvas-menu" style={{paddingBottom: 30}}>
                    <ul>
            
                    <li onClick={()=>setOpen(false)}><a href="https://aiwritergo.com"><b>Home</b></a></li>
                        <li onClick={()=>setOpen(false)}><Link to="/"><b>AI Writing Tools List</b></Link></li>
                        <li class={submenu.help ? "active" : ""}><div class="offcanvas-menu-expand" onClick={() => setSubmenu(prevSubmenu => ({help: !prevSubmenu.help}))}></div>
                            <a onClick={() => setSubmenu(prevSubmenu => ({help: !prevSubmenu.help, account: false}))} href="#"><span><b>Help Center</b></span></a>
                            <ul class="mobile-sub-menu" style={submenu.help ? {display: 'block'} : {display:'none'}}>
                                <li onClick={()=>setOpen(false)}><Link to="./faqs">FAQs</Link></li>
                                <li onClick={()=>setOpen(false)}><Link to="./requestfeature">Request New Features</Link></li>
                                <li onClick={()=>setOpen(false)}><Link to="./contactus">Contact Support</Link></li>
                            </ul>
                        </li>
                        <li onClick={()=>setOpen(false)} class="">
                            <Link to="./pricing"><span><b>Pricing</b></span></Link>
                        </li>

                        {
                            login ? 
                            <>
                            <li class={submenu.home ? "active" : ""}><div class="offcanvas-menu-expand" onClick={() => setSubmenu(prevSubmenu => ({account: !prevSubmenu.account}))}></div>
                            <a onClick={() => setSubmenu(prevSubmenu => ({account: !prevSubmenu.account, help: false}))} href="#"><span><b>My Account</b></span></a>
                            <ul class="mobile-sub-menu" style={submenu.account ? {display: 'block'} : {display:'none'}}>
                                <li onClick={()=>setOpen(false)}><Link to="./account-settings">Account Settings</Link></li>
                                <li onClick={()=>setOpen(false)}><Link to="./billing">Billing</Link></li>
                            </ul>
                        </li>
                        <li><a onClick={(e) => {logout(e); setOpen(false)}} href="#"><b>Log Out</b></a></li>
                            </>
                            :
                            <>
                            <li onClick={()=>setOpen(false)}><Link to='./login'><b>Sign In</b></Link></li>
                        <li onClick={()=>setOpen(false)}><Link to="./signup"><b>Create Account</b></Link></li>
                            </>
                        }
                        
                    </ul>
                    
                </div> 

               
            </div> 
          
        </div> 
    </div> 

    

    
    <Suspense fallback={<Coinloader />}>
    <Routes>
        <Route path="/" element={<Articlewriter />} />
        <Route path="login" exact element={<Login />} />
        <Route path="signup" exact element={<Createaccount />} />
        <Route path="login/recover" exact element={<Recover />} />
        <Route path="pricing" exact element={<Pricing />} />
        <Route path="faqs" exact element={<Faqs />} />
        <Route path="success" exact element={<Success />} />
        <Route path="contactus" exact element={<Contactus />} />
        <Route path="account-settings" exact element={<Accountsettings />} />
        <Route render path="billing" exact element={<Billing />} />
        {/* <Route render path="ai-article-writing-tool" exact element={<Articlewriter />} />
        <Route render path="ai-story-writing-tool" exact element={<Storywriter />} />
        <Route render path="ai-essay-writing-tool" exact element={<Essaywriter />} />
        <Route render path="ai-email-copywriting-tool" exact element={<Emailbodywriter />} />
        <Route render path="ai-youtube-video-description-writer-tool" exact element={<Youtubewriter />} />
        <Route render path="ai-amazon-product-description-writer-tool" exact element={<Amazonwriter />} />
        <Route render path="ai-quora-answer-writer-tool" exact element={<Quorawriter />} />
        <Route render path="ai-sales-copywriter-tool" exact element={<Salescopywriter />} />
        <Route path="test" exact element={<Toolpagetemplate />} /> */}
      </Routes>
      </Suspense>
  </div>
  <ToastContainer autoClose={false} />
    </>
  );
}

export default App;
